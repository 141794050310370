import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";

import {fetchConsultants, fetchContacts, fetchSubmissions,} from "appRedux/actions";
import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import { fetchOffers } from "../../appRedux/actions/Offer";
import { fetchAssessmentsList, fetchAssignedConsultantsTableData, fetchCurrentWeekOfferList, fetchDashboard, fetchMarketerStandupList, fetchMarketingProfile, fetchMarketingProfileList, fetchMonthwiseSubmissionsAndAssessmentsData, fetchOngoingOffers, fetchPocList, fetchStatusMarketingCount } from "../../appRedux/actions";
import { FilePptOutlined } from "@ant-design/icons";
import moment from "moment";
import { groups, levelsConst } from "../../constants/Constants";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;


const MarketerSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const currentDate = new Date();
  const defaultStartDate = moment(`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-01`);
  const defaultEndDate = moment();  
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'paginationInEmployeeConsultant','pagination']
    
  const fetchDashboardData = () => {
    dispatch(fetchOngoingOffers());
    dispatch(fetchStatusMarketingCount());
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchMonthwiseSubmissionsAndAssessmentsData())

    user && user.level && user.level == levelsConst['Executive'] &&
    dispatch(fetchAssignedConsultantsTableData(
      moment(defaultStartDate).format('YYYY-MM-DD'),
      moment(defaultEndDate).format('YYYY-MM-DD')
      ));
    if(user && user.level && user.groups.includes(groups['Marketing']) && 
      user.level <= levelsConst['Team Lead']){
      dispatch(fetchCurrentWeekOfferList())
    }
    // dispatch(fetchDashboard(user.id));
    // dispatch(fetchRecentRecruits());
    // dispatch(fetchRecruitsByStatus(user.id));
  };
  const userData =  JSON.parse(sessionStorage.getItem("authUser"));

  const fetchContactData = (contactType) => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchContacts({type:contactType}))

  };
  const fetchPocData = ()=>{
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchPocList());
  }

  const fetchingConsultantsData = () => {
    const storedDataString = sessionStorage.getItem('pagination');
    const storedData = storedDataString ? JSON.parse(storedDataString) : {};
  
    delete storedData.paginationPage;
    delete storedData.filterData;
    delete storedData.openTab;  
    storedData.resetPagination = 1;
  
    sessionStorage.setItem('pagination', JSON.stringify(storedData));
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchConsultants(null ,false,{ marketing_consultants:true }));
  };

  const fetchStandupList = () => {
    dispatch(fetchMarketerStandupList())
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchProfileList = () => {
    dispatch(fetchMarketingProfileList())
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchSubmissionsList = () => {
    dispatch(fetchSubmissions())
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchAssessments = () => {
    dispatch(fetchAssessmentsList())
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchOffersList = () => {
    dispatch(fetchOffers())
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  return (
         <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
           <Menu.Item key={"dashboard.".concat(user.username)}>
                        <Link to={"/".concat(user.username).concat('/dashboard/')} onClick={() => fetchDashboardData()}><i className="icon icon-dasbhoard"/>
                          <span><IntlMessages id="sidebar.dashboard"/></span></Link>
           </Menu.Item>
           { user && user.level && user.level <= levelsConst['Associate Team Lead'] && 
                <Menu.Item key="marketing.standup"> 
                  <Link to={"/".concat(user.username.concat("/marketing/standup"))}
                        onClick={() => fetchStandupList()}>
                    {/* <span style={groupActiveKey === 'marketing' && activeKey ===  'standup'  ? activeMenuItemStyle : null}> */}
                      <i className="icon icon-family"/><IntlMessages id="sidebar.standup"/>
                      </Link>
                </Menu.Item>
           }

                      <Menu.Item key="marketing.consultants">
                        {/* <Link to={"/".concat(user.username.concat("/marketing/consultant/list"))} onClick={() => dispatch(fetchConsultants())}><i className="icon icon-avatar"/> */}
                        <Link to={"/".concat(user.username.concat("/marketing/consultant/list"))} onClick={()=>fetchingConsultantsData()}><i className="icon icon-avatar"/>
                          <span><IntlMessages id="sidebar.consultants"/></span></Link>
                      </Menu.Item>
                      
                      <Menu.Item key="marketing.profiles">
                        <Link to={"/".concat(user.username.concat("/marketing/profiles/list"))} onClick={() =>fetchProfileList()}><i className="icon icon-card"/>
                         <span> <IntlMessages id="sidebar.profiles"/></span></Link>
                      </Menu.Item>

                      <Menu.Item key="marketing.submissions">
                        <Link to={"/".concat(user.username.concat("/marketing/submission/list"))} onClick={() => fetchSubmissionsList() }><i className="icon icon-card"/>
                         <span> <IntlMessages id="sidebar.submissions"/></span></Link>
                      </Menu.Item>
                      <Menu.Item key="marketing.assessments">
                        <Link to={"/".concat(user.username.concat("/marketing/assessment/list"))} onClick={() => fetchAssessments()}>
                        <i className="icon icon-card"/> <span> 
                          {/* <FilePptOutlined  style={{fontSize:'20px'}}/>  */}
                          <IntlMessages id="sidebar.assessments"/></span></Link>
                      </Menu.Item>
                   

                      <Menu.Item key="marketing.offers">
                        <Link to={"/".concat(user.username.concat("/marketing/offer/list"))} onClick={() => fetchOffersList()}><i className="icon icon-card"/>
                         <span> <IntlMessages id="sidebar.offers"/></span></Link>
                      </Menu.Item>
                      {userData && userData.permissions.includes('view_employee') &&
                      <Menu.Item key="marketers">
                        <Link to={"/".concat(user.username.concat("/marketing/marketer/list"))}>
                          <i className="icon icon-hotel-booking"/>
                          <span> <IntlMessages id="sidebar.marketers"/></span>
                        </Link>
                      </Menu.Item>
                       } 

           <MenuItemGroup style={{marginTop:'-50px', marginBottom:'-40px'}} key="marketing.contacts"> 
                        {/* <SubMenu key="contacts" // popupClassName={navStyleClass}
                               title={<span> <i className="icon icon-queries"/>
                                  <span><IntlMessages id="sidebar.contacts"/></span></span>}> */}
                      <Menu.Item style={{color:"white"}}>Contacts</Menu.Item>
                        <Menu.Item key="contacts.vendors">
                          <Link to={"/".concat(user.username.concat("/marketing/vendor/list"))} onClick={() => fetchContactData('v')}><i className="icon icon-contacts"/>
                            <span><IntlMessages id="sidebar.vendors"/></span></Link>
                        </Menu.Item>
                        <Menu.Item key="contacts.poc">
                          <Link to={"/".concat(user.username.concat("/marketing/poc/list"))} onClick={() => fetchPocData()}><i className="icon icon-all-contacts"/>
                            <span><IntlMessages id="sidebar.poc"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="contacts.ip">
                          <Link to={"/".concat(user.username.concat("/marketing/partner/list"))}  onClick={() => fetchContactData('i')}><i className="icon icon-diamond"/>
                           <span> <IntlMessages id="sidebar.ip"/></span></Link>
                        </Menu.Item>

                            <Menu.Item key="contacts.clients">
                          <Link to={"/".concat(user.username.concat("/marketing/client/list"))}  onClick={() => fetchContactData('c')}><i className="icon icon-company"/>
                           <span> <IntlMessages id="sidebar.clients"/></span></Link>
                        </Menu.Item>

                        <Menu.Item key="contacts.all">
                          <Link to={"/".concat(user.username.concat("/marketing/contact/list"))}  onClick={() => fetchContactData(null)}><i className="icon icon-all-contacts"/>
                            <span><IntlMessages id="sidebar.contacts.all"/></span></Link>
                        </Menu.Item>

                      {/* </SubMenu> */}

                    </MenuItemGroup>
         </Menu>
);
};
export default MarketerSidebar;
