// const ALLOWED_EXTENSIONS = ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'docx'];

export const compressImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      const fileType = file.type;
      if (!fileType.startsWith('image/')) {
        resolve(file);
        return;
      }

      reader.readAsDataURL(file); 
      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxSize = 700;

          let width = img.width;
          let height = img.height;

          if (width > height && width > maxSize) {
            height = Math.round((height * maxSize) / width);
            width = maxSize;
          } else if (height > width && height > maxSize) {
            width = Math.round((width * maxSize) / height);
            height = maxSize;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          // Convert the canvas to a Blob (compressed image)
          canvas.toBlob(
            (blob) => {
              if (blob) {
                const compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: Date.now(),
                });
                resolve(compressedFile);
              } else {
                reject(new Error('Blob conversion failed'));
              }
            },
            file.type,
            0.7 // Compression quality (0.7 is 70% quality)
          );
        };

        img.onerror = () => {
          reject(new Error('Error loading image'));
        };
      };

      reader.onerror = () => {
        reject(new Error('Error reading file'));
      };
    });
  };
