import React from "react";
import {
  ADD_CONSULTANT_FAILURE,
  ADD_CONSULTANT_SUCCESS,
  ADD_MARKETING_PROFILE_FAILURE,
  ADD_MARKETING_PROFILE_SUCCESS,
  ASSIGN_LEAD_FAILURE,
  ASSIGN_LEAD_SUCCESS,
  ASSIGN_MARKETER_FAILURE,
  ASSIGN_MARKETER_SUCCESS,
  CONSULTANT_INIT,
  DELETE_ATTACHMENT_FAILURE,
  DELETE_ATTACHMENT_SUCCESS,
  DELETE_CONSULTANT_FAILURE,
  DELETE_CONSULTANT_SUCCESS,
  DRAG_FAILED,
  DRAG_HAPPENED,
  EDIT_COMMENT_FAILURE,
  EDIT_COMMENT_SUCCESS,
  EDIT_CONSULTANT_FAILURE,
  EDIT_CONSULTANT_SUCCESS,
  FETCH_ATTACHMENTS_FAILURE,
  FETCH_ATTACHMENTS_SUCCESS,
  FETCH_COMMENTS_FAILURE,
  FETCH_COMMENTS_SUCCESS,
  FETCH_MARKETING_PROFILE_FAILURE,
  FETCH_MARKETING_PROFILE_LIST_FAILURE,
  FETCH_MARKETING_PROFILE_LIST_SUCCESS,
  FETCH_MARKETING_PROFILE_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  FETCH_MEMBERS_SUCCESS,
  FETCH_ONE_MARKETING_PROFILE_FAILURE,
  FETCH_ONE_MARKETING_PROFILE_SUCCESS,
  FETCH_SOURCE_FAILED,
  FETCH_SOURCE_SUCCESS,
  FETCH_TEAM_MARKETING_PROFILE_FAILURE,
  FETCH_TEAM_MARKETING_PROFILE_SUCCESS,
  FETCH_VISA_FAILED,
  FETCH_VISA_SUCCESS,
  GET_CONSULTANT_FAILURE,
  GET_CONSULTANT_SUCCESS,
  GET_CONSULTANTS_FAILURE,
  GET_CONSULTANTS_SUCCESS,
  GET_MARKETING_CONSULTANTS_FAILURE,
  GET_MARKETING_CONSULTANTS_SUCCESS,
  GET_SCREENING_CONSULTANTS_FAILURE,
  GET_SCREENING_CONSULTANTS_SUCCESS,
  GET_SEARCHED_CONSULTANT_FAILURE,
  GET_SEARCHED_CONSULTANT_SUCCESS,
  GET_TEAM_CONSULTANTS_FAILURE,
  GET_TEAM_CONSULTANTS_SUCCESS,
  UPDATE_MARKETING_PROFILE_FAILURE,
  UPDATE_MARKETING_PROFILE_SUCCESS,
  FETCH_MARKETING_PROFILE_LIST_By_SKILLS_SUCCESS,
  FETCH_CONTRACT_CONSULTANT_LIST_SUCCESS,
  FETCH_ACCOUNT_DETAILS_SUCCESS,
  FETCH_ACCOUNT_DETAILS_LIST_SUCCESS,
  FETCH_CONSULTANT_ACCOUNT_DETAILS_LIST_SUCCESS,
  FETCH_CONSULTANT_ASSIGNED_PAYEE_LIST_SUCCESS,
  FETCH_CONSULTANT_ACCOUNT_OBJECT_SUCCESS
} from "../../constants/ActionTypes";
import {fetchError, handleErrorResponse, fetchStart, fetchSuccess, showMessage, fetchRecruitsByStatus, handleAskOnboarding, handleRemoveDisableSubmitButton, fetchRecruiterConsultants} from "../../appRedux/actions";
import {
  ADD_ACCOUNT_DETAILS,
  ASSIGN_LEAD,
  ASSIGN_MARKETER,
  CONTINUE_MARKETING,
  CREATE_CONSULTANT,
  DELETE_ATTACHMENT,
  EDIT_PROJECT_COORDINATOR_ACCOUNT_DETAILS,
  EDIT_COMMENT,
  EDIT_CONSULTANT,
  EDIT_CONSULTANT_COMMENTS,
  EDIT_CONSULTANT_STATUS,
  FETCH_ATTACHMENTS,
  FETCH_COMMENTS,
  FETCH_MEMBERS,
  GET_ACCOUNT_DETAILS_LIST,
  GET_ALL_CONSULTANTS,
  GET_CONSULTANT,
  GET_CONSULTANTS,
  GET_CONTRACT_CONSULTANT_LIST,
  GET_MARKETING_CONSULTANTS,
  GET_SCREENING_CONSULTANTS,
  GET_SEARCHED_CONSULTANT,
  GET_TEAM_CONSULTANTS,
  MARKETING_PROFILE,
  MARKETING_PROFILE_TRANSFER,
  REMOVE_MARKETER,
  SEND_CONSULTANT_CREDENTIAL,
  SOURCE_OPTIONS,
  TEAM_MARKETING_PROFILE,
  UPDATE_MARKETING_PROFILE,
  VISA_STATUS,
  GET_CONSULTANT_ACCOUNT_DETAILS_LIST,
  ADD_COUNSULTANT_ACCOUNT_DETAILS,
  EDIT_CONSULTANT_ACCOUNT_DETAILS,
  GET_CONSULTANT_OFFERS_PAYEE_LIST,
  GET_CONSULTANT_OFFERS_ASSIGNED_PAYEE_LIST,
  GET_PROJECT_COORDINATORS_OFFERS_ASSIGNED_PAYEE_LIST,
  GET_CONSULTANT_ACCOUNT_DETAILS_OBJECT,
  GET_ACCOUNTS_SUPPORT_ACCOUNT_DETAILS_LIST,
  GET_ACCOUNT_SUPPORT_OFFERS_ASSIGNED_PAYEE_LIST,
  GET_CONSULTANT_ACCOUNT_DETAILS_FROM_OFFERS,
  GET_All_ACCOUNT_PAYEE_LIST,
  GET_ACCOUNTS_CONSULTANT_ACCOUNT_DETAILS_LIST
} from "../../constants/ApiEndpoints"
import {userService} from "../services";
import { Modal, Select, Button, Form, Input, message} from "antd";
import { defaultPageListLimit } from "../../constants/Constants";
const {Option} = Select;


export const consultantInit = () => {
  return {
    type: CONSULTANT_INIT,
  }
};


export const addConsultantSuccess =  (consultantData) => {
  return{
    type: ADD_CONSULTANT_SUCCESS,
    payload: consultantData
  }
};

export const addConsultantFailure =  () => {
  return {
    type: ADD_CONSULTANT_FAILURE
  }
};

export const addConsultant = (values) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(CREATE_CONSULTANT, values).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('add_new_consultant'))
        dispatch(addConsultantSuccess(response));
        dispatch(showMessage(`Successfully added ${response.full_legal_name}!`));
        dispatch(fetchConsultants());
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"consultantAdd": "success"}))
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_new_consultant'))
      dispatch(fetchError(error.data.error));
      dispatch(handleErrorResponse({"consultantAdd": error}))
      dispatch(addConsultantFailure());
    });
  }
};

export const fetchConsultantsSuccess = (consultants) => {
  return {
    type: GET_CONSULTANTS_SUCCESS,
    payload: consultants
  }
};

export const fetchConsultantsFailure = () => {
  return {
    type: GET_CONSULTANTS_FAILURE,
  }
};


export const fetchConsultants = (id=null, byEmployee=false, filterBy={page:1, status:null,
  department:null, rtg:null, ordering:null, key_skills:null, visa:null, min_rate:null, max_rate:null,
  teamlead:null, manager:null,recruiter:null, marketer:null ,from_date:null ,to_date:null ,q: null ,page_size:null ,
  marketing_consultants: false  }) => {
  return(dispatch) => {
  dispatch(fetchStart());
  if (byEmployee){
    filterBy['id'] = id;
    filterBy['by_employee'] = 'yes'
  }
  userService.get(GET_CONSULTANTS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchConsultantsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error?error.data.error:"Error Fetching consultants!"));
      dispatch(fetchConsultantsFailure());
    });
  }
};




export const fetchTeamConsultantsSuccess = (consultants) => {
  return {
    type: GET_TEAM_CONSULTANTS_SUCCESS,
    payload: consultants
  }
};

export const fetchTeamConsultantsFailure = () => {
  return {
    type: GET_TEAM_CONSULTANTS_FAILURE,
  }
};


export const fetchTeamConsultants = (isEmployer=false, filterBy={page:1, status:null,
  department:null, rtg:null, ordering:null ,include_self:false ,q:null ,page_size:null ,
  marketing_consultants: false }) => {
  return(dispatch) => {
  dispatch(fetchStart());
  // if (isEmployer){
  //   filterBy['is_employer'] = 'yes';
  // }
  userService.get(GET_TEAM_CONSULTANTS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchTeamConsultantsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTeamConsultantsFailure());
    });
  }
};

export const fetchAllConsultants = (filterBy={page:1, status:null, rtg:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_ALL_CONSULTANTS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchConsultantsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchConsultantsFailure());
    });
  }
};

export const editConsultantSuccess =  (consultantData) => {
  return{
    type: EDIT_CONSULTANT_SUCCESS,
    payload: consultantData
  }
};

export const editConsultantFailure =  () => {
  return {
    type: EDIT_CONSULTANT_FAILURE,
  }
};

export const editConsultant = (values, username, slug ,filterBy={updateTableCellOnResponse:()=>{}}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = EDIT_CONSULTANT.replace('slug', slug);
    userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        filterBy.updateTableCellOnResponse()
        dispatch(handleRemoveDisableSubmitButton('edit_consultant_form'))
        dispatch(editConsultantSuccess(response));
        // dispatch(fetchConsultant(slug));
        dispatch(fetchSuccess());
        dispatch(showMessage(`${response.profile.user.first_name} updated successfully.`));
        dispatch(handleErrorResponse({"consultantEdit": "success"}))
      }
    }).catch((error)=> {
      console.log("error 226",error)
      dispatch(handleRemoveDisableSubmitButton('edit_consultant_form'))
      dispatch(handleErrorResponse({"consultantEdit": error}))
      dispatch(fetchError(error.data.error?error.data.error:'Error Updating Consultant!'));

      // dispatch(editConsultantFailure());
    });
  }
};

export const updateConsultantDocuments = (values, consultantSlug) => {
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
    return (dispatch) => {
        dispatch(fetchStart());
        const endPoint = EDIT_CONSULTANT.replace('slug', consultantSlug);
        userService.upload(endPoint, values, headers)
            .then((response) => {
              if(response){
                dispatch(editConsultantSuccess(response.data.data));
                dispatch(fetchAttachments(consultantSlug));
                dispatch(fetchSuccess());
                dispatch(showMessage(response.data.message));
              }
              else{
                for (let key in response) {
                  for (let errMsg in response[key]){
                    const errorMsg = key.toUpperCase().concat(": ").concat(errMsg);
                    dispatch(fetchError(errorMsg));
                  }
                }
              }
            }).catch((error) => {
              dispatch(fetchError(error?error.data?error.data.error:"error uploading document!":error));
              dispatch(editConsultantFailure());
        });
    }
};

export const updateConsultantComments = (values, consultantSlug) => {
  return (dispatch) => {
        dispatch(fetchStart());
        const endPoint = EDIT_CONSULTANT_COMMENTS.replace('slug', consultantSlug);
        userService.post(endPoint, values)
            .then((response) => {
              if(response){
                dispatch(editConsultantSuccess(response));
                dispatch(fetchComments(consultantSlug));
                dispatch(fetchSuccess());
              }
              else{
                for (let key in response) {
                  for (let errMsg in response[key]){
                    const errorMsg = key.toUpperCase().concat(": ").concat(errMsg);
                    dispatch(fetchError(errorMsg));
                  }
                }
              }
            }).catch((error) => {
              dispatch(fetchError(error.data.error));
              dispatch(editConsultantFailure());
        });
    }

};

export const deleteConsultantSuccess =  () => {
  return{
    type: DELETE_CONSULTANT_SUCCESS,
  }
};

export const deleteConsultantFailure =  () => {
  return {
    type: DELETE_CONSULTANT_FAILURE,
  }
};

export const deleteConsultant = (history, username, consultantSlug, consultantUserId) => {
    return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = EDIT_CONSULTANT.replace('slug', consultantSlug);
    const values = {
        'user': {
          'is_active': false,
          'id': consultantUserId
        },
        'is_removed': true
    };
    userService.patch(apiEndpoint, values).then((response) => {
     if (response) {
        dispatch(deleteConsultantSuccess());
        dispatch(showMessage(response));
        dispatch(fetchConsultants());
        dispatch(fetchSuccess());
        history.push('/'.concat(username).concat('/recruitment/consultant/list'));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(deleteConsultantFailure());

    });
    }
};

export const fetchConsultantSuccess = (consultant) => {
  return {
    type: GET_CONSULTANT_SUCCESS,
    payload: consultant
  }
};

export const fetchConsultantFailure = () => {
  return {
    type: GET_CONSULTANT_FAILURE,
  }
};


export const fetchConsultant = (slug) => {
  return (dispatch) => {
    dispatch(consultantInit());
    dispatch(fetchStart());
    let apiEndpoint = GET_CONSULTANT.replace('slug', slug);
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        if (typeof response === "string"){
          dispatch(fetchError(response));
          dispatch(fetchConsultantFailure());
        }
        else {
          dispatch(fetchConsultantSuccess(response));
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      if(error && error.data && error.data.error){
        dispatch(fetchError(error.data.error));
      }
      dispatch(fetchConsultantFailure());

    });
  }
};


export const updateConsultantStatusSuccess =  () => {
  return {
    type: DRAG_HAPPENED
    }
};

export const updateConsultantStatusFailure =  (originalList) => {
  return {
    type: DRAG_FAILED,
    payload: {
      originalList
    }
  }
};


export const updateStatus =  (droppableIdEnd, draggableId, user, originalList ,filterBy = {marketers:[] , card:[] 
    ,handlerApiResponse : () => {} ,currentStatus: null ,slug: null ,consultantsInitialEmial: null  
    ,listToFetchAfterUpdateByEmployeeId:null ,isMarketer:false ,updateTableCellOnResponse:()=>{}}) => {
  // listToFetchAfterUpdateByEmployeeId this is the Id of the recruiter or marketer . which is employee Id to fetch the list again after the responce . 

  const closeModal = () => {
    Modal.destroyAll(); 
  };

  const handleOnboardingMail = (dispatch,user) => {
     const handleSubmit = (value) => {
      if(!value){
        message.alert("Email is required !")
        return
      }
      if(value && filterBy && filterBy.slug){
          dispatch(handleAskOnboarding(filterBy.slug ,{email :value.email}));
          closeModal()
      }
    }
    Modal.confirm({
      title: "Send Onboarding Mail",
      content: (
      <Form onFinish={handleSubmit} layout="vertical">
                  <Form.Item
                    label="Are you sure want to send mail?"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' },
                              {type:"email" ,message:"please enter valid email !"}
                          ]}
                    initialValue={filterBy.consultantsInitialEmial}
                  >
                    <Input placeholder={"Enter email"} />
                  </Form.Item>
                  <Form.Item align="right" style={{  justifyContent: "flex-end" }}>
                    <Button className='primary' onClick={closeModal}>Cancel</Button>
                    <Button type="primary" htmlType="submit">OK</Button>
                  </Form.Item>
          </Form>
    ),
    okButtonProps: { 
      style: { display: 'none' }, 
    },
    cancelButtonProps: { 
      style: { display: 'none' }, 
    },
    });
  }

  return(dispatch) => {
  dispatch(fetchStart());
  const endPointToUse = filterBy.isMarketer === true  ? EDIT_CONSULTANT_STATUS : EDIT_CONSULTANT 
  const endPoint = endPointToUse.replace('slug', draggableId);
  const payload = {
        'status': droppableIdEnd
        };
  if (droppableIdEnd === 'r' || droppableIdEnd === 'f' || droppableIdEnd === 'l') {
     payload['is_removed'] = true;
  }
  userService.patch(endPoint, payload).then((response) => {
      if (response) {
        dispatch(fetchError())
        dispatch(updateConsultantStatusSuccess());
        if(filterBy && filterBy.listToFetchAfterUpdateByEmployeeId){
          const storedDataString = sessionStorage.getItem('paginationInEmployeeConsultant');
          const innitialPage = storedDataString ? JSON.parse(storedDataString).pageNumber : 1;
          dispatch(fetchRecruiterConsultants(innitialPage, filterBy.listToFetchAfterUpdateByEmployeeId));
        }
        if(droppableIdEnd === 's'){
          handleOnboardingMail(dispatch ,user)
        }
        if(droppableIdEnd === 'm'){
          filterBy.handlerApiResponse()
        }
        dispatch(fetchSuccess());
        filterBy.updateTableCellOnResponse() // this will update the cell in the table
        dispatch(showMessage('Updated successfully !'))
        filterBy.updateTableCellOnResponse() // this will update the cell in the table
      }
    }).catch(function (error) {
      // filterBy.handlerApiResponse("error" ,filterBy.currentStatus ,draggableId)
      if(error && error.data && error.data.error){
        dispatch(fetchError(error.data.error));
      }
        if(error && error.data && error.data.errors){
          const errorKey = Object.keys(error.data.errors)
              errorKey.forEach((key)=>{
                dispatch(fetchError(error.data.errors[key]));
              })
        }
      dispatch(fetchRecruitsByStatus())
      // dispatch(updateConsultantStatusFailure(originalList));
    });
  }
};

export const marketerAssignSuccess =  () => {
  return {
    type: ASSIGN_MARKETER_SUCCESS
    }
};

export const marketerAssignFailure =  () => {
  return {
    type: ASSIGN_MARKETER_FAILURE,
    }
};


export const assignMarketer =  (employeeId, consultantSlug ,consultantIn ,filterBy={disableButtonHandler:(() => {}) ,
  closeAssignedMarketerModel:(() => {}),listToFetchAfterUpdateByEmployeeId:null ,updateTableCellOnResponse:(()=>{}), 
  items:[]}) => {
  // listToFetchAfterUpdateByEmployeeId this is the Id of the recruiter or marketer . which is employee Id to fetch the list again after the responce . 
  const storedDataString = sessionStorage.getItem('pagination');
  const storedPageSizeString = sessionStorage.getItem('pageSize');
  const storedData = storedDataString ? JSON.parse(storedDataString) : {};
  const storedPageSize = storedPageSizeString ? JSON.parse(storedPageSizeString) : defaultPageListLimit;
  const openTab = storedData &&  storedData.openTab === 'teamConsultantList' ? storedData.openTab : null ;
  const pageToFetch = storedData &&  storedData.paginationPage ? storedData.paginationPage : 1 ;
  return(dispatch) => {
  dispatch(fetchStart());
  const endPoint = ASSIGN_MARKETER.replace('slug', consultantSlug);
  // const payload = {
  //       'employee': employeeId
  //       };
  const payload = {
    'marketers': employeeId
    };
        
  userService.post(endPoint, payload).then((response) => {
      if (response) {
        filterBy.disableButtonHandler(false)
        filterBy.closeAssignedMarketerModel(true)
        dispatch(marketerAssignSuccess());
        if(filterBy && filterBy.items){
          // filterBy.updateTableCellOnResponse(filterBy.items)
        }
        if(filterBy && !filterBy.listToFetchAfterUpdateByEmployeeId){
          if(consultantIn === "marketing") { 
            if(openTab === 'teamConsultantList'){
              if(storedData && storedData.filterData){
                dispatch(fetchTeamConsultants(true, {page: pageToFetch, marketing_consultants: true, page_size: storedPageSize, ...storedData.filterData})) //added {status:'m'} to fetch the team consultant whose status is marketing 
                return
              }
              dispatch(fetchTeamConsultants(true, {page: pageToFetch, marketing_consultants: true, page_size: storedPageSize})) //added {status:'m'} to fetch the team consultant whose status is marketing 
            }
            else{
              if(storedData && storedData.filterData){
              dispatch(fetchConsultants(null, false ,{marketing_consultants:true ,page: pageToFetch, page_size: storedPageSize, ...storedData.filterData})) //added {status:'m'} to fetch the consultant whose status is marketing
              return 
              }
              dispatch(fetchConsultants(null, false ,{marketing_consultants:true ,page: pageToFetch, page_size: storedPageSize})) //added {status:'m'} to fetch the consultant whose status is marketing 
            }
          }
          else{
            if(openTab === 'teamConsultantList'){
              if(storedData && storedData.filterData){
                dispatch(fetchTeamConsultants(true, {page: pageToFetch, page_size: storedPageSize, ...storedData.filterData})) // remove this if not need this is added to fetch the list with filters
                return
              }
              dispatch(fetchTeamConsultants(true, {page: pageToFetch, page_size: storedPageSize})) //added to fetch the team consultant whose status is recruitment 
            }
            else{
              if(storedData && storedData.filterData){
                dispatch(fetchConsultants(null, false ,{page: pageToFetch, page_size: storedPageSize, ...storedData.filterData})); // remove this if not need this is added to fetch the list with filters
                return
              }
              dispatch(fetchConsultants(null, false ,{page: pageToFetch, page_size: storedPageSize}));
            }
          }
          }else{
            const storedDataString = sessionStorage.getItem('paginationInEmployeeConsultant');
            const storedpageSize = sessionStorage.getItem('pageSize');
            const innitialPage = storedDataString ? JSON.parse(storedDataString).pageNumber : 1;
            const innitialPageSize = storedpageSize ? JSON.parse(storedpageSize): defaultPageListLimit;
            if(consultantIn === "marketing"){ // this is used tto fetch the list again with the pagesize
              dispatch(fetchRecruiterConsultants(innitialPage, filterBy.listToFetchAfterUpdateByEmployeeId,null,null,null,true,{marketing_consultants:true, page_size:innitialPageSize}));
            }else{
              dispatch(fetchRecruiterConsultants(innitialPage, filterBy.listToFetchAfterUpdateByEmployeeId,null,null,null,true,{ page_size:innitialPageSize}));
            }
          }
          dispatch(showMessage(response.message?response.message:"Marketers updated Successfully!")); 
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      filterBy.disableButtonHandler(false)
      dispatch(fetchError(error.data.error));
      if(error.data && error.data.errors ){
        if (Array.isArray(error.data.errors)) {
            error.data.errors.forEach((err, index) => {
              message.error(err.error);
          });
        }
      }
      dispatch(marketerAssignFailure());
    });
  }
};

export const removeMarketer =  (payload ,consultantIn ,filterBy={disableButtonHandler: () => {} ,
    closeAssignedMarketerModel: () => {} ,listToFetchAfterUpdateByEmployeeId:null  ,updateTableCellOnResponse:(()=>{}), 
    items:[] }) => {
  // listToFetchAfterUpdateByEmployeeId this is the Id of the recruiter or marketer . which is employee Id to fetch the list again after the responce . 
  const storedDataString = sessionStorage.getItem('pagination');
  const storedPageSizeString = sessionStorage.getItem('pageSize');
  const storedPageSize = storedPageSizeString ? JSON.parse(storedPageSizeString) : defaultPageListLimit;
  const storedData = storedDataString ? JSON.parse(storedDataString) : {};
  const openTab = storedData &&  storedData.openTab === 'teamConsultantList' ? storedData.openTab : null ;
  const pageToFetch = storedData &&  storedData.paginationPage ? storedData.paginationPage : 1 ;
  return(dispatch) => {
  dispatch(fetchStart());
  userService.patch(REMOVE_MARKETER, payload).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        // filterBy.updateTableCellOnResponse(filterBy.items)
        filterBy.disableButtonHandler(false)
        filterBy.closeAssignedMarketerModel(true)
        dispatch(showMessage("Marketers updated Successfully!"));
        if(filterBy && !filterBy.listToFetchAfterUpdateByEmployeeId){
        if(consultantIn === "marketing") {
          if(openTab === 'teamConsultantList'){
            dispatch(fetchTeamConsultants(true, {marketing_consultants:true ,page: pageToFetch, marketing_consultants: true, page_size: storedPageSize})) //added {status:'m'} to fetch the team consultant whose status is marketing 
          }
          else{
            dispatch(fetchConsultants(null, false  ,{marketing_consultants: true, page: pageToFetch, page_size: storedPageSize})) //added {status:'m'} to fetch the consultant whose status is marketing after removing marketer 
          }
        }
        else{
          if(openTab === 'teamConsultantList'){
            dispatch(fetchTeamConsultants(true, {page: pageToFetch, page_size: storedPageSize})) //added  to fetch the team consultant whose status is recruitment after removing marketer
          }
          else{
            dispatch(fetchConsultants(null, false ,{page: pageToFetch, page_size: storedPageSize} )); 
          }
        } 
      }else{
        const storedDataString = sessionStorage.getItem('paginationInEmployeeConsultant');
        const innitialPage = storedDataString ? JSON.parse(storedDataString).pageNumber : 1;
        const storedpageSize = sessionStorage.getItem('pageSize');
        const innitialPageSize = storedpageSize ? JSON.parse(storedpageSize): defaultPageListLimit;
        if(consultantIn === "marketing"){  // this is used tto fetch the list again with the pagesize
          dispatch(fetchRecruiterConsultants(innitialPage, filterBy.listToFetchAfterUpdateByEmployeeId,null,null,null,true,{marketing_consultants:true, page_size:innitialPageSize}));
        }else{
        dispatch(fetchRecruiterConsultants(innitialPage, filterBy.listToFetchAfterUpdateByEmployeeId,null,null,null,true,{ page_size:innitialPageSize}));
        }
      }
    }
    }).catch(function (error) {
      filterBy.disableButtonHandler(false)
      dispatch(fetchError(error.data.error?error.data.error:"Error updating marketers!"));
    });
  }
};


export const leadAssignSuccess =  () => {
  return {
    type: ASSIGN_LEAD_SUCCESS
    }
};

export const leadAssignFailure =  () => {
  return {
    type: ASSIGN_LEAD_FAILURE,
    }
};


export const assignLead =  (dataObj) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.patch(ASSIGN_LEAD, dataObj).then((response) => {
      if (response) {
        dispatch(leadAssignSuccess());
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(leadAssignFailure());
    });
  }
};


export const deleteAttachmentSuccess =  () => {
  return{
    type: DELETE_ATTACHMENT_SUCCESS,
  }
};

export const deleteAttachmentFailure =  () => {
  return {
    type: DELETE_ATTACHMENT_FAILURE,
  }
};

export const deleteAttachment = (attachmentId, consultantSlug) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = DELETE_ATTACHMENT.replace('id', attachmentId);
    userService.destroy(endPoint).then((response) => {
      if(response){
        dispatch(deleteAttachmentSuccess());
        dispatch(fetchAttachments(consultantSlug));
        dispatch(fetchSuccess());
        dispatch(showMessage(response));

      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(deleteAttachmentFailure());
    });
  }
};

export const fetchMembersSuccess =  (memberData) => {
  return{
    type: FETCH_MEMBERS_SUCCESS,
    payload: memberData
  }
};

export const fetchMembersFailure =  () => {
  return {
    type: FETCH_MEMBERS_FAILURE,
  }
};


export const fetchMembers = (consultantSlug) => {
  return  (dispatch) => {
    dispatch(fetchStart());
    const endPoint = FETCH_MEMBERS.replace('slug', consultantSlug);
    userService.get(endPoint).then((response) => {
      if(response){
        dispatch(fetchMembersSuccess(response.members));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchMembersFailure());
    });
  }
};


export const fetchAttachmentsSuccess =  (attachmentData) => {
  return{
    type: FETCH_ATTACHMENTS_SUCCESS,
    payload: attachmentData
  }
};

export const fetchAttachmentsFailure =  () => {
  return {
    type: FETCH_ATTACHMENTS_FAILURE,
  }
};


export const fetchAttachments = (consultantSlug) => {
  return  (dispatch) => {
    dispatch(fetchStart());
    const endPoint = FETCH_ATTACHMENTS.replace('slug', consultantSlug);
    userService.get(endPoint).then((response) => {
      if(response){
        dispatch(fetchAttachmentsSuccess(response.attachments));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchAttachmentsFailure());
    });
  }
};


export const fetchCommentsSuccess =  (commentData) => {
  return{
    type: FETCH_COMMENTS_SUCCESS,
    payload: commentData
  }
};

export const fetchCommentsFailure =  () => {
  return {
    type: FETCH_COMMENTS_FAILURE,
  }
};


export const fetchComments = (consultantSlug) => {
  return  (dispatch) => {
    dispatch(fetchStart());
    const endPoint = FETCH_COMMENTS.replace('slug', consultantSlug);
    userService.get(endPoint).then((response) => {
      if(response){
        dispatch(fetchCommentsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data?error.data.error?error.data.error:"Error fetching comment":"Error fetching comment"));
      dispatch(fetchCommentsFailure());
    });
  }
};


export const updateCommentSuccess =  () => {
  return{
    type: EDIT_COMMENT_SUCCESS,
  }
};

export const updateCommentFailure =  () => {
  return {
    type: EDIT_COMMENT_FAILURE,
  }
};

export const updateComment = (values, commentId, consultantSlug) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = EDIT_COMMENT.replace('id', commentId);
    userService.patch(endPoint, values).then((response) => {
      if(response){
        dispatch(updateCommentSuccess());
        dispatch(fetchComments(consultantSlug));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(updateCommentFailure());
    });
  }
};



export const fetchVisaSuccess =  (response) => {
  return{
    type: FETCH_VISA_SUCCESS,
    payload: response
  }
};

export const fetchVisaFailed =  () => {
  return {
    type: FETCH_VISA_FAILED,
  }
};

export const fetchVisaStatus = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(VISA_STATUS).then((response) => {
      if(response){
        dispatch(fetchVisaSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchVisaFailed());

    })
  }
};


export const fetchSourceSuccess =  (response) => {
  return{
    type: FETCH_SOURCE_SUCCESS,
    payload: response
  }
};

export const fetchSourceFailed =  () => {
  return {
    type: FETCH_SOURCE_FAILED,
  }
};

export const fetchSourceOptions = () => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(SOURCE_OPTIONS).then((response) => {
      if(response){
        dispatch(fetchSourceSuccess(response.results));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchSourceFailed());

    })
  }
};


export const fetchMarketingProfileSuccess = (marketingProfiles) => {
  return {
    type: FETCH_MARKETING_PROFILE_SUCCESS,
    payload: marketingProfiles
  }
};
export const fetchMarketingProfileFailure = () => {
  return {
    type: FETCH_MARKETING_PROFILE_FAILURE,
  }
};


export const fetchMarketingProfile = (consultant, filterBy = {q:null}) => {
  
  return (dispatch) => {
    dispatch(fetchStart());
      var consultantObj = {
        "consultant_id": consultant,
        "name": filterBy.q
        }

    userService.get(MARKETING_PROFILE, consultantObj).then((response) => {
      if (response) {
        dispatch(fetchMarketingProfileSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      console.log("error 737",error)
      dispatch(fetchMarketingProfileFailure());

    });
  }
};

// marketing profile sidebar page
export const fetchMarketingProfileListSuccess = (marketingProfileList) => {
  return {
    type: FETCH_MARKETING_PROFILE_LIST_SUCCESS,
    payload: marketingProfileList
  }
};
export const fetchMarketingProfileListFailure = () => {
  return {
    type: FETCH_MARKETING_PROFILE_LIST_FAILURE,
  }
};


export const fetchMarketingProfileList = (filterBy={page:1 ,inactive:null}) => {
  
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(MARKETING_PROFILE ,filterBy).then((response) => {
      if (response) {
        dispatch(fetchMarketingProfileListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchMarketingProfileListFailure());

    });
  }
};

export const fetchTeamMarketingProfileSuccess = (marketingProfileList) => {
  return {
    type: FETCH_TEAM_MARKETING_PROFILE_SUCCESS,
    payload: marketingProfileList
  }
};
export const fetchTeamMarketingProfileFailure = () => {
  return {
    type: FETCH_TEAM_MARKETING_PROFILE_FAILURE,
  }
};


export const fetchMarketingTeamProfileList = (page=1) => {
  
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(TEAM_MARKETING_PROFILE ,page).then((response) => {
      if (response) {
        dispatch(fetchTeamMarketingProfileSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchTeamMarketingProfileFailure());

    });
  }
};

export const fetchOneMarketingProfileSuccess = (marketingProfile) => {
  return {
    type: FETCH_ONE_MARKETING_PROFILE_SUCCESS,
    payload: marketingProfile
  }
};

export const fetchOneMarketingProfileFailure = () => {
  return {
    type: FETCH_ONE_MARKETING_PROFILE_FAILURE,
  }
};


export const fetchOneMarketingProfile = (profileId) => {
  
  return (dispatch) => {
    dispatch(fetchStart());
    const endPoint = MARKETING_PROFILE.concat(profileId.toString(), "/"); 
    userService.get(endPoint).then((response) => {
      if (response) {
        dispatch(fetchOneMarketingProfileSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchOneMarketingProfileFailure());
    });
  }
};

export const addMarketingProfileSuccess =  () => {
  return {
    type: ADD_MARKETING_PROFILE_SUCCESS
    }
};

export const addMarketingProfileFailure =  () => {
  return {
    type: ADD_MARKETING_PROFILE_FAILURE,
    }
};

export const addMarketingProfile = (consultant, formData) => {
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(MARKETING_PROFILE, formData, headers).then((response) => {
      if (response) {
        dispatch(fetchMarketingProfile(consultant));
        dispatch(addMarketingProfileSuccess(response));
        dispatch(handleRemoveDisableSubmitButton('add_marketing_profile'))
        dispatch(showMessage(`Successfully added Profile!`));
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"marketingProfileAdd": "success"}))
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_marketing_profile'))
      dispatch(fetchError(error.data.error?error.data.error:'Error adding profile!'));
      dispatch(handleErrorResponse({"marketingProfileAdd": error}));
      dispatch(addMarketingProfileFailure());
    });
  }
}


export const updateMarketingProfileSuccess =  () => {
  return {
    type: UPDATE_MARKETING_PROFILE_SUCCESS,
    }
};

export const updateMarketingProfileFailure =  () => {
  return {
    type: UPDATE_MARKETING_PROFILE_FAILURE,
    }
};

export const updateMarketingProfile = (consultant, profId, formData ,fetchList={openTabKey:null}) => {
  const  headers = {
    'Content-Type': 'multipart/form-data'
  }
  return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = UPDATE_MARKETING_PROFILE.replace('profId', profId);
    userService.put(apiEndpoint, formData, headers).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('edit_marketing_profile'))
        dispatch(updateMarketingProfileSuccess(response));
        dispatch(showMessage(`Successfully updated Profile!`));
        dispatch(fetchMarketingProfile(consultant));
        if(fetchList && fetchList.openTabKey && fetchList.openTabKey === 'teamMarketingProfiles'){
          dispatch(fetchMarketingTeamProfileList({page: 1}));
        }
        else{
          dispatch(fetchMarketingProfileList());
        }
        dispatch(fetchSuccess());
        dispatch(handleErrorResponse({"marketingProfileEdit": "success"}))
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(handleRemoveDisableSubmitButton('edit_marketing_profile'))
      dispatch(handleErrorResponse({"marketingProfileEdit": error}))
      dispatch(updateMarketingProfileFailure());
    });
  }
}

// fetch consultants for screening

export const fetchScreeningConsultantsSuccess = (screeningConsultants) => {
  return {
    type: GET_SCREENING_CONSULTANTS_SUCCESS,
    payload: screeningConsultants.results
  }
};

export const fetchScreeningConsultantsFailure = () => {
  return {
    type: GET_SCREENING_CONSULTANTS_FAILURE,
  }
};


export const fetchScreeningConsultants = (filterBy={status:"n"}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_SCREENING_CONSULTANTS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchScreeningConsultantsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchScreeningConsultantsFailure());
    });
  }
};

// FETCH ALL CONSULTANTS FOR MARKETING (USING SPECIALLY FOR ADDING MARKETING PROFILE)

export const fetchMarketingConsultantsSuccess = (marketingConsultants) => {
  return {
    type: GET_MARKETING_CONSULTANTS_SUCCESS,
    payload: marketingConsultants.results
  }
};

export const fetchMarketingConsultantsFailure = () => {
  return {
    type: GET_MARKETING_CONSULTANTS_FAILURE,
  }
}
// consultant search individual data fetch


export const fetchSearchedConsultantSuccess = (searchedConsultant) => {
  return {
    type: GET_SEARCHED_CONSULTANT_SUCCESS,
    payload: searchedConsultant
  }
};

export const fetchSearchedConsultantFailure = () => {
  return {
    type: GET_SEARCHED_CONSULTANT_FAILURE,
  }
}


export const fetchMarketingConsultants = () => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_MARKETING_CONSULTANTS).then((response) => {
      if (response) {
        dispatch(fetchMarketingConsultantsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
    dispatch(fetchError(error.data.error));
    dispatch(fetchMarketingConsultantsFailure());
  });
}
};


export const fetchSearchedConsultant = (conId) => {
  return(dispatch) => {
  dispatch(fetchStart());
  let apiEndpoint = GET_SEARCHED_CONSULTANT.replace('conId', conId);
  userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchSearchedConsultantSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchSearchedConsultantFailure());
    });
  }
};

// fetching the profile list on the basis of the skills 

export const fetchMarketingProfileListBySkillsSuccess = (marketingProfileList) => {
  return {
    type: FETCH_MARKETING_PROFILE_LIST_By_SKILLS_SUCCESS,
    payload: marketingProfileList
  }
};

export const fetchMarketingProfileListBySkills = (filterBy={page:1 ,inactive:null ,technology:null}) => {
  
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(MARKETING_PROFILE ,filterBy).then((response) => {
      if (response) {
        dispatch(fetchMarketingProfileListBySkillsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchMarketingProfileListFailure());

    });
  }
};


// transfer marketing profile


export const transferMarketingProfile = (values, profId, consultant=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = MARKETING_PROFILE_TRANSFER.replace('profId', profId);
    userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        if(consultant){
          dispatch(fetchMarketingProfile(consultant))
        }else{
          dispatch(fetchMarketingProfileList());
        }
        dispatch(showMessage(`Profile Transfer Completed Successfully!`));
      }
    }).catch((error)=> {
      dispatch(fetchError(error.data.error));
      dispatch(fetchSearchedConsultantFailure());
    });
  }
}

// continue marketing api

export const fetchContinueMarketing = (filterBy={enabled:null ,consultantSlug:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = CONTINUE_MARKETING.replace('slug',filterBy.consultantSlug);
    delete filterBy.consultantSlug
    userService.patch(apiEndpoint, filterBy).then((response) => {
      if (response) {
        dispatch(showMessage(`Updated Successfully!`));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

// contract

export const fetchContractConsultantSuccess = (marketingProfileList) => {
  return {
    type: FETCH_CONTRACT_CONSULTANT_LIST_SUCCESS,
    payload: marketingProfileList
  }
};

export const fetchContractConsultantList = (filterBy={page:1}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.get(GET_CONTRACT_CONSULTANT_LIST ,filterBy).then((response) => {
      if (response) {
        dispatch(fetchContractConsultantSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const sendConsultantCredential = (filterBy={slug:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    let apiEndpoint = SEND_CONSULTANT_CREDENTIAL.replace('slug',filterBy.slug)
    userService.post(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        dispatch(showMessage(`Send Successfully!`));
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      message.error(`Problem sending credential!`)
    });
  }
};

// consultant 

export const addAccountDetails = (filterBy={values:null, initialAccountDetailsList:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_ACCOUNT_DETAILS ,filterBy.values).then((response) => {
      if (response) {
        if(response && filterBy && filterBy.initialAccountDetailsList && filterBy.initialAccountDetailsList.length > 0){
          const setValue = {
            "count":  [response, ...filterBy.initialAccountDetailsList] && [response, ...filterBy.initialAccountDetailsList].length,
            "results": [response]
          }
          dispatch(fetchAccountDetailsListSuccess(setValue));
        }else{
          if(response){
            const setValue = { "count": 1, "results": [response]}
            dispatch(fetchAccountDetailsListSuccess(setValue));
          }
        }
        dispatch(handleErrorResponse({"addAccountDetails": "success"}))
        dispatch(showMessage(`Successful!`));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleErrorResponse({"addAccountDetails": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};

export const addConsultantAccountDetails = (filterBy={values:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_COUNSULTANT_ACCOUNT_DETAILS ,filterBy.values).then((response) => {
      if (response) {
        dispatch(handleErrorResponse({"addAccountDetails": "success"}))
        dispatch(showMessage(`Successful!`));
        const storedCurrentPageInSession = parseInt(sessionStorage.getItem('accountDetatailPage',10)|| 1)
        dispatch(fetchConsultantAccountDetailsList({page:storedCurrentPageInSession}))
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleErrorResponse({"addAccountDetails": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchAccountDetailsListSuccess = (response) => {
  return {
    type: FETCH_ACCOUNT_DETAILS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchAccountDetailsList = (filterBy={page: 1,recordId:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_ACCOUNT_DETAILS_LIST.concat(`?support_person_id=${filterBy.recordId}&page=${filterBy.page}`)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchAccountDetailsListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchAccountsSupportAccountDetailsList = (filterBy={page: 1,recordId:null}) => { // this is to fetch the account list of the support person in the accounts depatment
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_ACCOUNTS_SUPPORT_ACCOUNT_DETAILS_LIST.concat(`?support_person_id=${filterBy.recordId}&page=${filterBy.page}`)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchAccountDetailsListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const editAccountDetails = (filterBy={value:null, id:null, updateTableCellOnResponse:()=>{},
  supportPersonId:null  }) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_PROJECT_COORDINATOR_ACCOUNT_DETAILS.replace('id',filterBy.id).replace('supprtPersonId',filterBy.supportPersonId)
    userService.patch(apiEndpoint,filterBy.value).then((response) => {
      if (response) {
         if(filterBy && filterBy.updateTableCellOnResponse){
          filterBy.updateTableCellOnResponse(response, filterBy.id)
        }
        dispatch(showMessage(`Successful!`));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      message.error('Problem while Updating!')
      dispatch(fetchError(error.data.error));
    });
  }
};

export const editCounsultantAccountDetails = (filterBy={value:null, id:null, updateTableCellOnResponse:()=>{}, fetchListOnResponce:false, isConsultant:false}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = EDIT_CONSULTANT_ACCOUNT_DETAILS.replace('id',filterBy.id)
    userService.patch(apiEndpoint,filterBy.value).then((response) => {
      if (response) {
        if(filterBy && filterBy.updateTableCellOnResponse && (filterBy.fetchListOnResponce === false || filterBy.fetchListOnResponce === undefined )){
          filterBy.updateTableCellOnResponse(response, filterBy.id)
        }
        if(filterBy.fetchListOnResponce === true && filterBy.isConsultant=== true){
          const storedCurrentPageInSession = parseInt(sessionStorage.getItem('accountDetatailPage',10)|| 1)
          dispatch(fetchConsultantAccountDetailsList({page: storedCurrentPageInSession}))
        }
        dispatch(showMessage(`Successful!`));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      message.error('Problem while Updating!')
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchConsultantAccountDetailsListSuccess = (response) => {
  return {
    type: FETCH_CONSULTANT_ACCOUNT_DETAILS_LIST_SUCCESS,
    payload: response
  }
};

export const fetchAllAccountDetailsList = (filterBy={page:1, support_person_id:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = filterBy.support_person_id && (filterBy.support_person_id !== undefined ||
      filterBy.support_person_id !== null) ? 
      GET_ACCOUNT_DETAILS_LIST.concat(`?page=${filterBy.page}&support_person_id=${filterBy.support_person_id}`)
      :
      GET_ACCOUNT_DETAILS_LIST.concat(`?page=${filterBy.page}`)

      userService.get(apiEndpoint).then((response) => {
        if (response) {
          dispatch(fetchConsultantAccountDetailsListSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
  }
};

export const fetchConsultantAccountDetailsList = (filterBy={page: 1}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_CONSULTANT_ACCOUNT_DETAILS_LIST.concat(`?page=${filterBy.page}`)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchConsultantAccountDetailsListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchIndividualConsultantAccountDetailsSuccess = (response) => {
  return {
    type: FETCH_CONSULTANT_ACCOUNT_OBJECT_SUCCESS,
    payload: response
  }
};

export const fetchAccountConsultantAccountDetails = (filterBy={page:1, consultant_id:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_CONSULTANT_ACCOUNT_DETAILS_OBJECT.concat(`?page=${filterBy.page}&consultant_id=${filterBy.consultant_id}`)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchIndividualConsultantAccountDetailsSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchConsultantOffersAssignedPayeeListSuccess = (response) => {
  return {
    type: FETCH_CONSULTANT_ASSIGNED_PAYEE_LIST_SUCCESS,
    payload: response
  }
};

export const fetchConsultantOffersAssignedPayeeList = (filterBy={offerId: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_CONSULTANT_OFFERS_ASSIGNED_PAYEE_LIST.replace('offerId',filterBy.offerId)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchConsultantOffersAssignedPayeeListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchProjectCoordinatorOfferPayeesList = (filterBy={offerId: null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_PROJECT_COORDINATORS_OFFERS_ASSIGNED_PAYEE_LIST.replace('offerId',filterBy.offerId)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchConsultantOffersAssignedPayeeListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const fetchAccountOfferSupportPayeesList = (filterBy={offerId: null, pagination:false}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = filterBy.offerId !== undefined ? GET_ACCOUNT_SUPPORT_OFFERS_ASSIGNED_PAYEE_LIST.concat(`&offer=${filterBy.offerId}`).concat('&pagination=false')
    :
    GET_All_ACCOUNT_PAYEE_LIST.concat('?pagination=false')

    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchConsultantOffersAssignedPayeeListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const resetAccountOfferSupportPayeesList = () => {
  return (dispatch) => {
    dispatch(fetchConsultantOffersAssignedPayeeListSuccess(null))
  }
}

export const fetchAccountDetailsListFromAccountsOffersList = (filterBy={page:1, support_person_id:null,consultant_id:null, offer_id:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = filterBy.support_person_id && (filterBy.support_person_id !== undefined ||
      filterBy.support_person_id !== null) ? 
      GET_CONSULTANT_ACCOUNT_DETAILS_FROM_OFFERS.concat(`?page=${filterBy.page}&support_person_id=${filterBy.support_person_id}`)
      :
      filterBy.consultant_id && (filterBy.consultant_id !== undefined || filterBy.consultant_id !== null) ? 
      GET_CONSULTANT_ACCOUNT_DETAILS_FROM_OFFERS.concat(`?page=${filterBy.page}&consultant_id=${filterBy.consultant_id}&offer_id=${filterBy.offer_id}`)
      :
      GET_CONSULTANT_ACCOUNT_DETAILS_FROM_OFFERS.concat(`?page=${filterBy.page}`)

      userService.get(apiEndpoint).then((response) => {
        if (response) {
          dispatch(fetchConsultantAccountDetailsListSuccess(response));
          dispatch(fetchSuccess());
        }
      }).catch(function (error) {
        dispatch(fetchError(error.data.error));
      });
  }
};

export const resetAccountDetailsListFromAccountsOffersList = () => {
  return (dispatch) => {
    dispatch(fetchConsultantAccountDetailsListSuccess({ results:null, count:0 }));
  }
}

export const fetchAccountsConsultantAccountDetailsList = (filterBy={page: 1,recordId:null}) => { // this is to fetch the account list of the support person in the accounts depatment
  return (dispatch) => {
    dispatch(fetchStart());
    const apiEndpoint = GET_ACCOUNTS_CONSULTANT_ACCOUNT_DETAILS_LIST.concat(`?consultant_id=${filterBy.recordId}&page=${filterBy.page}`)
    userService.get(apiEndpoint).then((response) => {
      if (response) {
        dispatch(fetchAccountDetailsListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
    });
  }
};

export const addAccountConsultantAccountDetails = (filterBy={values:null, initialAccountDetailsList:null}) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(GET_ACCOUNTS_CONSULTANT_ACCOUNT_DETAILS_LIST ,filterBy.values).then((response) => {
      if (response) {
        dispatch(handleErrorResponse({"addAccountDetails": "success"}))
        if(response && filterBy && filterBy.initialAccountDetailsList && filterBy.initialAccountDetailsList.length > 0){
          const setValue = {
            "count":  [response, ...filterBy.initialAccountDetailsList] && [response, ...filterBy.initialAccountDetailsList].length,
            "results": [response]
          }
          dispatch(fetchAccountDetailsListSuccess(setValue));
        }else{
          if(response){
            const setValue = { "count": 1, "results": [response]}
            dispatch(fetchAccountDetailsListSuccess(setValue));
          }
        }
        dispatch(showMessage(`Successful!`));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleErrorResponse({"addAccountDetails": error}))
      dispatch(fetchError(error.data.error));
    });
  }
};
