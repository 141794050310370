import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchEmployees } from "../../appRedux/actions";

const HumanResourceSideBar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'supportPersonsoffersPageNo']
    
  const fetchProjectCoordinators = () => {
    dispatch(fetchEmployees(1 ,null ,'project_coordination', 'user'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchRecruiterList = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchEmployees(1, null, 'recruitment', 'user'));
  };

  const fetchMarketerList = () => {
    DeleteKeysFromSession(keysToDeleteFromSession)
    dispatch(fetchEmployees(1, null, 'marketing', 'user'));
  };

  const fetchEngineersList = () => {
    dispatch(fetchEmployees(1 ,null ,'engineering'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchContractPersonsList = () => {
    dispatch(fetchEmployees(1 ,null ,'contract'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  return (
        <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">

          <Menu.Item style={{color:"white"}}>Employee</Menu.Item>
          <Menu.Item key="employee.recruiters">
            <Link to={"/".concat(user.username.concat("/human_resource/recruiter/list"))}
                  onClick={() => fetchRecruiterList()}
                  >
                <i className="icon icon-profile"/><IntlMessages id="sidebar.recruiters"/></Link>
          </Menu.Item>
          <Menu.Item key="employee.marketers">
            <Link to={"/".concat(user.username.concat("/human_resource/marketer/list"))}
                  onClick={() => fetchMarketerList()}
                  >
              <i className="icon icon-profile"/>
                <IntlMessages id="sidebar.marketers"/></Link>
          </Menu.Item>
          <Menu.Item key="project_coordination.projectcoordinators">
            <Link to={"/".concat(user.username.concat("/human_resource/project_coordinators"))} 
              onClick={()=>fetchProjectCoordinators()}
              > <i className="icon icon-card"/>
              <span style={{ display: 'inline-block', lineHeight: 1, marginBottom: '0px', transform: 'translate(0px, 8px)', top: '10px'}} >
                Project <br></br> Coordinator
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key="employee.projectcoordinators">
            <Link to={"/".concat(user.username.concat("/human_resource/engineers/list"))} 
              onClick={()=>fetchEngineersList()}
              > <i className="icon icon-card"/>
              <IntlMessages id='sidebar.listType.engineers' />
            </Link>
          </Menu.Item>
          <Menu.Item key="contract.contrats">
              <Link to={"/".concat(user.username.concat("/human_resource/contract-person/list"))} 
              onClick={()=>fetchContractPersonsList()} > <i className="icon icon-card"/>
              Contract
              </Link>
          </Menu.Item>
        </Menu>
);
};
export default HumanResourceSideBar;
