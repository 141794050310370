import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

const AsyncProjectCoordinators = asyncComponent(() => import('../projectCoordination/projectCoordinators/index'));
const AsyncMarketerList = asyncComponent(() => import('../marketing/marketer/index'));
const AsyncRecruiter = asyncComponent(() => import('../recruitment/recruiter/index'));
const AsyncRecruiterEdit = asyncComponent(() => import('../recruitment/recruiter/edit'));
const AsyncMarketerEdit = asyncComponent(() => import('../marketing/marketer/edit'));
const AsyncProjectCoordinatorEdit = asyncComponent(() => import('../projectCoordination/projectCoordinators/edit'));
const AsyncEngieersList = asyncComponent(() => import('../engineer/engineers/index'));
const AsyncEngieerEdit = asyncComponent(() => import('../engineer/engineers/edit'));
const AsyncContractPerson = asyncComponent(() => import('../contract/contractPerson/index'));
const AsyncContractPersonEdit = asyncComponent(() => import('../contract/contractPerson/edit'));

const HumanResource = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/human_resource`} to={`${props.match.url}`}/>
        <PropsRoute path={`${props.match.url}/project_coordinators/edit/:project_coordinatorsId`} 
          dispatch={props.dispatch} component={AsyncProjectCoordinatorEdit} user={props.user}/>
        <PropsRoute path={`${props.match.url}/marketer/edit/:marketerId`} dispatch={props.dispatch}
          component={AsyncMarketerEdit} user={props.user}/>
        <PropsRoute path={`${props.match.url}/marketer/list`} dispatch={props.dispatch}
          component={AsyncMarketerList} user={props.user}/>
        <PropsRoute path={`${props.match.url}/recruiter/list`} dispatch={props.dispatch}
          component={AsyncRecruiter} user={props.user}/>
        <PropsRoute path={`${props.match.url}/project_coordinators`} dispatch={props.dispatch}
          component={AsyncProjectCoordinators} user={props.user}/>
        <PropsRoute path={`${props.match.url}/recruiter/edit/:recruiterId`} dispatch={props.dispatch}
          component={AsyncRecruiterEdit} user={props.user}/>
        <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
          component={AsyncEngieersList} user={props.user}/>
        <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
          dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/>
        <PropsRoute path={`${props.match.url}/contract-person/list`} dispatch={props.dispatch}
          component={AsyncContractPerson} user={props.user}/>
        <PropsRoute path={`${props.match.url}/contract-person/edit/:contractPersonId`} 
          dispatch={props.dispatch} component={AsyncContractPersonEdit} user={props.user}/>
  </Switch>
);

export default HumanResource;
