import {
  GET_ALL_CLIENT_FAILED,
  GET_ALL_CLIENT_SUCCESS,
  GET_ALL_CONTACT_FAILED,
  GET_ALL_CONTACT_SUCCESS,
  GET_ALL_CONTACT_WITH_SAMW_NAME_SUCCESS,
  GET_ALL_PARTNER_SUCCESS,
  GET_ALL_VENDOR_SUCCESS,
  GET_MERGE_CLIENT_LIST_SUCCESS,
  GET_POC_LIST_FAILED,
  GET_POC_LIST_SUCCESS,
  ON_ADD_CONTACT_FAILED,
  ON_ADD_CONTACT_SUCCESS,
  ON_ADD_POC_FAILED,
  ON_ADD_POC_SUCCESS,
  REMOVED_DUPLICATES_SUCCESS,
  RESET_MERGE_CLIENT_LIST
} from "../../constants/ActionTypes";

import {fetchError, fetchStart, fetchSuccess, handleErrorResponse, handleRemoveDisableSubmitButton, showMessage} from "./Common";
import {ADD_CLIENTS, ADD_POC, EDIT_CLIENTS, GET_CLIENTS, GET_CONTACTS, REMOVE_DUPLICATE, UPDATE_POC} from "../../constants/ApiEndpoints";
import {userService} from "../services";
import { message } from "antd";


export const fetchContactsSuccess = (contacts) => {
  return {
    type: GET_ALL_CONTACT_SUCCESS,
    payload: contacts
  }
};

export const fetchContactsFailure = () => {
  return {
    type: GET_ALL_CONTACT_FAILED,
  }
};


//previous - export const fetchContacts = (filterBy={page:null, type:null, client__type:null, client__name:null}) => {

export const fetchContacts = (filterBy={page:null, type:null, page_size:null}) => {
  const payloadToSend = {
    ...filterBy,
    type: filterBy.type && Array.isArray(filterBy.type) ? filterBy.type.join(',') : filterBy.type,
  }
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_CLIENTS, payloadToSend).then((response) => {
      if (response) {
        dispatch(fetchContactsSuccess(response));
        dispatch(fetchSuccess());
        if(filterBy['type'] ==='v'){
          dispatch(fetchVendorsSuccess(response));
        }else{

        }
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      dispatch(fetchContactsFailure());
    });
  };
};

export const fetchPocListSuccess = (pocList) => {
  return {
    type: GET_POC_LIST_SUCCESS,
    payload: pocList
  }
};

export const fetchExistingContactsWithSameNameSuccess = (contacts) => {
  return {
    type: GET_ALL_CONTACT_WITH_SAMW_NAME_SUCCESS,
    payload: contacts
  }
};

export const fetchExistingContactsWithSameName = (filterBy={q:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_CLIENTS, filterBy).then((response) => {
      if (response) {
        dispatch(fetchExistingContactsWithSameNameSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(showMessage("error fetching existing conultant with same name !"))
      // dispatch(fetchError(error.data.error));
      dispatch(fetchContactsFailure());
    });
  };
};


export const fetchPocListFailure = () => {
  return {
    type: GET_POC_LIST_FAILED,
  }
};
export const fetchPocList = (filterBy={page:null, client:null, type: null ,q:null ,page_size:null}) => {
  const payloadToSend = {
    ...filterBy,
    type: filterBy && filterBy.type && Array.isArray(filterBy.type) ? filterBy.type.join(',') : filterBy.type,
  }
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_CONTACTS, payloadToSend).then((response) => {
      if (response) {
        dispatch(fetchPocListSuccess(response));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(fetchError(error.data.error));
      dispatch(fetchPocListFailure());
    });
  };
};

export const fetchClientsSuccess = (clients) => {
  return {
    type: GET_ALL_CLIENT_SUCCESS,
    payload: clients
  }
};

export const fetchClientsFailure = () => {
  return {
    type: GET_ALL_CLIENT_FAILED,
  }
};

export const fetchVendorsSuccess = (vendors) => {
  return {
    type: GET_ALL_VENDOR_SUCCESS,
    payload: vendors
  }
};

export const fetchPartnersSuccess = (partners) => {
  return {
    type: GET_ALL_PARTNER_SUCCESS,
    payload: partners
  }
};

export const fetchMergeClientSuccess = (vendors) => {
  return {
    type: GET_MERGE_CLIENT_LIST_SUCCESS,
    payload: vendors
  }
};

export const resetMergeClientList = () => {
  return {
    type: RESET_MERGE_CLIENT_LIST,
    payload: {
      count: 0,
      results:[] 
    }
  }
};

export const fetchClients = (filterBy={page:null, type:null, ordering:null, q:null}) => {
  return(dispatch) => {
  dispatch(fetchStart());
  userService.get(GET_CLIENTS, filterBy).then((response) => {
      if (response) {
        if(filterBy['type'] === 'c'){
          dispatch(fetchClientsSuccess(response))
        }
        else if (filterBy['type'] === 'i'){
          dispatch(fetchPartnersSuccess(response))
        }
        else{
          dispatch(fetchVendorsSuccess(response));
          dispatch(fetchMergeClientSuccess(response));
        }
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      // dispatch(fetchError(error.data.error));
      dispatch(fetchClientsFailure());
    });
  };
};

export const addContactSuccess =  (contactData) => {
  return{
    type: ON_ADD_CONTACT_SUCCESS,
    payload: contactData
  }
};

export const addContactFailure =  () => {
  return {
    type: ON_ADD_CONTACT_FAILED
  }
};

export const addContact = (values, clientType=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_CLIENTS, values).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('add_new_client'))
        dispatch(handleErrorResponse({"contactAdd": "success"}))
        dispatch(addContactSuccess(response));
        dispatch(showMessage(`Successfully added ${values.name}.`));
        dispatch(fetchContacts({type:clientType}));
        dispatch(fetchClients({type:clientType}));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_new_client'))
      dispatch(handleErrorResponse({"contactAdd": error}))
      dispatch(fetchError(error.data.error));
      dispatch(addContactFailure());
    });
  }
};

export const updateContact = (values, clientId, clientType=null) => {
  let apiEndpoint = EDIT_CLIENTS.replace('clientId', clientId);
  return (dispatch) => {
    dispatch(fetchStart());
    userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('edit_client_form'))
       dispatch(handleErrorResponse({"clientEdit": "success"}))
        dispatch(showMessage(`Successfully updated ${values.name}.`));
        dispatch(fetchContacts());
        dispatch(fetchClients({type:clientType}));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleErrorResponse({"clientEdit": error}))
      dispatch(handleRemoveDisableSubmitButton('edit_client_form'))
      dispatch(fetchError(error.data.error));
      dispatch(addContactFailure());
    });
  }
};


// add poc
export const addPocSuccess =  (pocData) => {
  return{
    type: ON_ADD_POC_SUCCESS,
    payload: pocData
  }
};

export const addPocFailure =  () => {
  return {
    type: ON_ADD_POC_FAILED
  }
};

export const addPoc = (values, client=null) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(ADD_POC, values).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('add_new_poc'))
        dispatch(handleErrorResponse({"pocAdd": "success"}));
        message.success("Successfully added POC.")
        dispatch(addPocSuccess(response));
        // dispatch(fetchPocList({client: client}));
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('add_new_poc'))
      if (error && error.data && error.data.errors && error.data.errors[0]) {
        if (error.data.errors[0].phone_number && error.data.errors[0].phone_number) {
            dispatch(fetchError(error.data.errors[0].phone_number));
        }
    }
      dispatch(handleErrorResponse({"pocAdd": error}))
      dispatch(fetchError(error.data.error));
      dispatch(addPocFailure());
    });
  }
};

export const updatePoc = (values, pocId) => {
  let apiEndpoint = UPDATE_POC.replace('pocId', pocId);
  return (dispatch) => {
    dispatch(fetchStart());
    userService.patch(apiEndpoint, values).then((response) => {
      if (response) {
        dispatch(handleRemoveDisableSubmitButton('edit_poc_details_form'))
       dispatch(handleErrorResponse({"pocEdit": "success"}))
        dispatch(showMessage(`Successfully updated ${values.name}.`));
        dispatch(fetchPocList());
        dispatch(fetchSuccess());
      }
    }).catch(function (error) {
      dispatch(handleRemoveDisableSubmitButton('edit_poc_details_form'))
      dispatch(handleErrorResponse({"pocEdit": error}))
      if (error && error.data && error.data.errors && typeof error.data.errors === 'object') {
        Object.keys(error.data.errors).forEach(key => {
          const errorMessages = error.data.errors[key];
          if (Array.isArray(errorMessages)) {
            errorMessages.forEach(errorMessage => {
              dispatch(fetchError(errorMessage));
            });
          } else {
            dispatch(fetchError(errorMessages));
          }
        });
      } 
        dispatch(fetchError(error.data.error));
    });
  }
};


export const removeDuplicates = (values) => {
  return (dispatch) => {
    dispatch(fetchStart());
    userService.post(REMOVE_DUPLICATE, values).then((response) => {
      if (response) {
        dispatch(fetchSuccess());
        sessionStorage.removeItem('clientPagination')
        dispatch(resetMergeClientList())
        dispatch(fetchClients())
        dispatch(showMessage(`Merged successfully !`));
      }
    }).catch(function (error) {
      dispatch(fetchError('Problem merging client!'))
      dispatch(fetchError(error.data.error));
    });
  }
};