import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Contact from "./Contact";
import Common from "./Common";
import Consultants from "./Consultants";
import Employees from "./Employees";
import User from "./Users";
import Submissions from "./Submissions";
import Essentials from "./Essentials";
import Offer from './Offer';
import Account from './Accounts';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  contact: Contact,
  common: Common,
  consultants: Consultants,
  employees: Employees,
  user: User,
  submissions: Submissions,
  essentials: Essentials,
  offer: Offer,
  accounts: Account
});


export default createRootReducer
