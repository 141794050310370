import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";

import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
// import { employeeInhouseOffersList } from "../../appRedux/actions/Offer";
import moment from "moment";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchContractConsultantList, fetchContractTeamOffersList, fetchEmployees, fetchEngineerOffersList, fetchProjectCoordinatorsOffersList, fetchSupportPersonsList } from "../../appRedux/actions";
import { levelsConst } from "../../constants/Constants";

const ContractSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const currentDate = new Date();
  const defaultStartDate = moment(`${currentDate.getFullYear()}-${currentDate.getMonth()+1}-01`);
  const defaultEndDate = moment();  
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'supportPersonsoffersPageNo','joiningPendingTablePageNumber','incompleteVarificationTablePageNumber',
    'varifiedTablePageNumber','consultantPageNumber']
    
  const fetchDashboardData = () => {
    dispatch(fetchContractTeamOffersList({page:1, status:'j'}))
    dispatch(fetchContractTeamOffersList({page:1, verified:false}))
    dispatch(fetchContractTeamOffersList({page:1, verified:true}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }
  const fetchContractPersonsList = () => {
    dispatch(fetchEmployees(1 ,null ,'contract'))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchConsultantList = () => {
    dispatch(fetchContractConsultantList({page:1}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  return (
        <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
          <Menu.Item key="engineering.recruitment">
            <Link to={"/username/dashboard".replace('username', user.username)}
                  onClick={()=>fetchDashboardData()}>
              <span><i className="icon icon-auth-screen"/><IntlMessages id="Dashboard"/></span>
              </Link>
          </Menu.Item>
          <Menu.Item key="contract.consultant">
            <Link to={"/username/contract/consultant/list".replace('username', user.username)}
                  onClick={()=>fetchConsultantList()}>
              <span><i className="icon icon-auth-screen"/><IntlMessages id="sidebar.consultants"/></span>
              </Link>
          </Menu.Item>
          { user && user.level && user.level <= levelsConst['Team Lead'] && 
          <Menu.Item key="contract.contrats">
              <Link to={"/".concat(user.username.concat("/contract/contract-person/list"))} 
              onClick={()=>fetchContractPersonsList()} > <i className="icon icon-card"/>
              Contract
              </Link>
          </Menu.Item>
            }
        </Menu>
);
};
export default ContractSidebar;
