import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncAccountDetails = asyncComponent(() => import('./accountDetails/index'));
const AsyncReimbursement = asyncComponent(() => import('./reimbursement/index'));

const Consultant = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/consultant`} to={`${props.match.url}`}/>
        {/* <PropsRoute path={`${props.match.url}/engineers/list`} dispatch={props.dispatch}
          component={AsyncEngieersList} user={props.user}/>
        <PropsRoute path={`${props.match.url}/engineers/edit/:engineerId`} 
          dispatch={props.dispatch} component={AsyncEngieerEdit} user={props.user}/> */}
        <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>
        <PropsRoute path={`${props.match.url}/account-details`} 
          dispatch={props.dispatch} component={AsyncAccountDetails} user={props.user}/>
        <PropsRoute path={`${props.match.url}/reimbursement`} 
          dispatch={props.dispatch} component={AsyncReimbursement} user={props.user}/>

  </Switch>
);

export default Consultant;
