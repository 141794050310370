import React from "react";
import {Redirect, Switch} from "react-router";
import {PropsRoute} from "../../util/routeRestriction";
import asyncComponent from "../../util/asyncComponent";

// const AsyncProjectCoordinatorSupportPerson = asyncComponent(() => import(''));
const AsyncAccountReceivable = asyncComponent(() => import('./accountReceivable/index'));
const AsyncAccountConsultant = asyncComponent(() => import('./consultant'));
const AsyncAccountConsultantView = asyncComponent(() => import('./consultant/edit'));
const AsyncAccountSupport = asyncComponent(() => import('./support'));
const AsyncAccountTransactions = asyncComponent(() => import('./transactions'));
const AsyncTimeSheet = asyncComponent(() => import('../engineer/timeSheet/index'));
const AsyncPayroll = asyncComponent(() => import('../../components/Accounts/payroll/index'));

const Accounts = (props) => (
  <Switch>
    <Redirect exact from={`${props.match.url}/accounts`} to={`${props.match.url}`}/>
        {/* <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
          component={AsyncProjectCoordinatorSupportPerson} user={props.user}/> */}
        <PropsRoute path={`${props.match.url}/consultant/view/:consultantSlug`} dispatch={props.dispatch}
          component={AsyncAccountConsultantView} user={props.user}/>
        <PropsRoute path={`${props.match.url}/account-receivable/list`} dispatch={props.dispatch}
          component={AsyncAccountReceivable} user={props.user}/>
        <PropsRoute path={`${props.match.url}/consultant/list`} dispatch={props.dispatch}
          component={AsyncAccountConsultant} user={props.user}/>
        <PropsRoute path={`${props.match.url}/support_person`} dispatch={props.dispatch}
          component={AsyncAccountSupport} user={props.user}/>
        <PropsRoute path={`${props.match.url}/transaction`} dispatch={props.dispatch}
          component={AsyncAccountTransactions} user={props.user}/>
        <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/>
        <PropsRoute path={`${props.match.url}/support/payroll/:consultantId`} 
          dispatch={props.dispatch} component={AsyncPayroll} user={props.user}/>
        <PropsRoute path={`${props.match.url}/consultant/payroll/:consultantId`} 
          dispatch={props.dispatch} component={AsyncPayroll} user={props.user}/>
        {/* <PropsRoute path={`${props.match.url}/timesheet/:offerId`} 
          dispatch={props.dispatch} component={AsyncTimeSheet} user={props.user}/> */}

  </Switch>
);

export default Accounts;
