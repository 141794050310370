import IntlMessages from "util/IntlMessages";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import React from "react";
import {useDispatch} from "react-redux";
import {THEME_TYPE_LITE} from "../../constants/ThemeSetting";
import moment from "moment";
import { DeleteKeysFromSession } from "../../routes/recruitment/screening/utils";
import { fetchConsultantAccountDetailsList, fetchConsultantOffersList, fetchSupportPersonsList } from "../../appRedux/actions";
import { fetchAccountConsultantList, fetchAccountReceivableList, fetchAccountsOffersList, fetchTransactionList } from "../../appRedux/actions/Accounts";

const AccountSidebar = ({navStyleClass, defaultOpenKeys, selectedKeys, themeType, user}) => {
  const dispatch = useDispatch();

  const keysToDeleteFromSession = ['pageSize','employeePageNo','profilePageNo','submissionPageNo',
    'assessmentPageNo','clientPagination','pocPageNo','ipPageNo','transferRequestPageNo','offersPageNo',
    'supportPersonsoffersPageNo','ongoingTablePageNumber','accountDetatailPage','pagination','transactionListPage'
  ]
    
  const fetchDashboardData = () => {
    dispatch(fetchAccountsOffersList({page:1, status:'ongoing'}))
    dispatch(fetchAccountsOffersList({page:1, status:'closed'}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchAccountDetails = () => {
    dispatch(fetchAccountReceivableList({page: 1}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }
  const fetchSupportPerson = () => {
    dispatch(fetchSupportPersonsList({pagination:true}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  const fetchConsultant = () => {
    dispatch(fetchAccountConsultantList({page: 1}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }
  const fetchTransaction = () => {
    dispatch(fetchTransactionList({page: 1}))
    DeleteKeysFromSession(keysToDeleteFromSession)
  }

  return (
        <Menu defaultOpenKeys={[defaultOpenKeys]} selectedKeys={[selectedKeys]} theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'} mode="inline">
          <Menu.Item key="account.dashboad">
            <Link to={"/username/dashboard".replace('username', user.username)}
              onClick={()=>fetchDashboardData()}> <span>
              <i className="icon icon-auth-screen"/><IntlMessages id="Dashboard"/></span>
            </Link>
          </Menu.Item>
          <Menu.Item key="account.consultant">
            <Link to={"/username/accounts/consultant/list".replace('username', user.username)}
                onClick={()=>fetchConsultant()}> <i className="icon icon-auth-screen"/>
                  Consultant
              </Link>
          </Menu.Item>
          <Menu.Item key="account.support">
            <Link to={"/username/accounts/support_person".replace('username', user.username)}
                onClick={()=>fetchSupportPerson()}> <i className="icon icon-auth-screen"/>
                  Support
              </Link>
          </Menu.Item>
          <Menu.Item key="account.receivable">
            <Link to={"/username/accounts/account-receivable/list".replace('username', user.username)}
                onClick={()=>fetchAccountDetails()}> <i className="icon icon-auth-screen"/>
                    <span style={{ display: 'inline-block', lineHeight: 1, marginBottom: '0px', transform: 'translate(0px, 8px)', top: '10px'}} >
                    Account <br></br> Receivable</span>
              </Link>
          </Menu.Item>
          <Menu.Item key="account.transaction">
            <Link to={"/username/accounts/transaction".replace('username', user.username)}
                onClick={()=>fetchTransaction()}> <i className="icon icon-auth-screen"/>
                  Transactions
              </Link>
          </Menu.Item>
        </Menu>
);
};
export default AccountSidebar;
